import * as actionTypes from './action';

interface InitialState {
  theme: string;
}

const initialState = {
  theme: 'A',
};

const reducer = (state: InitialState = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.CHANGE_THEME:
      return {
        ...state,
        theme: action.value,
      };
    default:
      return state;
  }
};

export default reducer;

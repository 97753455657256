import { combineReducers } from 'redux';
import themeReducer from './themeReducer';
//import * as actionTypes from './action';

//const initialState = {
//  theme: 'A',
//};

export const rootReducer = combineReducers({
  //  theme: themeReducer,
  themeReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
/*
// Reducer
const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.CHANGE_THEME:
      return {
        ...state,
        theme: action.value,
      };
  }
  return state;
};

export default reducer;*/

import React, { RefObject } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  Divider,
  SelectPicker,
  SelectPickerProps,
  Icon,
} from 'rsuite';
import { RootState } from '../../../store/rootReducer';
import Scale from '../Scale/Scale';
import ChartScale from '../Chart/ChartScale';
import { WasmService } from '../../../WasmService';
import {
  SelectService,
  DtoSelectScale,
  DtoSelectNote,
  DtoSelectFret,
  DtoSelectPreview,
} from './SelectService';
import { ReactService } from '../../../ReactService';

interface Props {
  theme: string; // redux
}

interface States {
  wasm: any;
  type?: string; // TODO rename with select (not important)
  tonic?: string; // TODO rename tonic in service associated
  fret: number;
  preview: string;
}

class SelectScale extends React.Component<Props, States> {
  private typeRef: RefObject<SelectPickerProps> = React.createRef();
  private tonicRef: RefObject<SelectPickerProps> = React.createRef();
  private fretRef: RefObject<SelectPickerProps> = React.createRef();
  private previewRef: RefObject<SelectPickerProps> = React.createRef();

  public state: States = {
    wasm: null,
    type: undefined,
    tonic: undefined,
    fret: 0,
    preview: 'chord',
  };

  public componentDidMount(): void {
    ReactService.importThemeLess(this.props.theme);
    const wasm = new WasmService();
    wasm
      .loadWasm()
      .then((success) => this.setState({ wasm: success }))
      .catch((err) => console.log(err)); // TODO better
  }

  /*
   * Fill Scale and retun DtoSelectScale[]
   */
  private fillSelectType(): DtoSelectScale[] {
    const service = new SelectService(
      this.state.wasm,
      ReactService.computeTheme(this.props.theme)
    );
    const dto: DtoSelectScale[] = service.getScale();
    service.free();
    return dto;
  }

  /*
   * Fill note and return DtoSelectNote[]
   */
  private fillSelectTonic(): DtoSelectNote[] {
    const dto = SelectService.getNote();
    return dto;
  }

  /*
   * Fill fret and return DtoSelectFret[]
   */
  private fillSelectFret(): DtoSelectFret[] {
    const dto = SelectService.getFret();
    return dto;
  }

  /*
   * Fill fret and return DtoSelectFret[]
   */
  private fillSelectPreview(): DtoSelectPreview[] {
    const dto = SelectService.getPreview(false);
    return dto;
  }

  public render(): JSX.Element {
    return (
      <div>
        <h1>Select scale</h1>
        <Grid fluid>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={6}>
              {this.state.wasm ? (
                <SelectPicker
                  ref={this.typeRef}
                  data={this.fillSelectType()}
                  value={this.state.type}
                  cleanable={false}
                  block
                  placeholder="Select type"
                  renderMenuItem={(label) => {
                    return (
                      <div>
                        <Icon icon={'music'} /> {label}
                      </div>
                    );
                  }}
                  renderValue={(value) => {
                    return (
                      <div>
                        Type:{' '}
                        {this.fillSelectType().find((x) => x.value === value)
                          ?.label || value}
                      </div>
                    );
                  }}
                  onChange={(v) => {
                    this.setState({ type: v });
                  }}
                />
              ) : (
                <SelectPicker
                  ref={this.typeRef}
                  data={[]}
                  disabled
                  placeholder="Select type (loading...)"
                />
              )}
            </Col>
            <Col xs={24} sm={24} md={6}>
              <SelectPicker
                ref={this.tonicRef}
                data={this.fillSelectTonic()}
                value={this.state.tonic}
                cleanable={false}
                block
                placeholder="Select tonic note"
                renderMenuItem={(label) => {
                  const icon: string =
                    this.fillSelectTonic().find((x) => x.label === label)
                      ?.icon || 'circle-o';
                  // white note
                  if (icon === 'circle-o')
                    return (
                      <div>
                        <Icon icon={'circle-o'} /> {label}
                      </div>
                    );
                  // black note
                  else
                    return (
                      <div>
                        <Icon icon={'circle'} /> {label}
                      </div>
                    );
                }}
                renderValue={(value) => {
                  return <div>Note: {value}</div>;
                }}
                onChange={(v) => {
                  this.setState({ tonic: v });
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <SelectPicker
                ref={this.fretRef}
                data={this.fillSelectFret()}
                value={this.state.fret}
                cleanable={false}
                block
                placeholder="Select fret position"
                renderMenuItem={(label) => {
                  return <div>{label}</div>;
                }}
                renderValue={(value) => {
                  const fret: string =
                    this.fillSelectFret().find((x) => x.value === value)
                      ?.label || value;
                  return <div>Type: {fret}</div>;
                }}
                onChange={(v) => {
                  this.setState({ fret: v });
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <SelectPicker
                ref={this.previewRef}
                data={this.fillSelectPreview()}
                value={this.state.preview}
                cleanable={false}
                block
                placeholder="Select music preview"
                renderMenuItem={(label) => {
                  const icon: string =
                    this.fillSelectPreview().find((x) => x.label === label)
                      ?.icon || 'ban';
                  // music preview
                  if (icon === 'music')
                    return (
                      <div>
                        <Icon icon={'music'} /> {label}
                      </div>
                    );
                  // no preview
                  else
                    return (
                      <div>
                        <Icon icon={'ban'} /> {label}
                      </div>
                    );
                }}
                renderValue={(value) => {
                  const preview: string =
                    this.fillSelectPreview().find((x) => x.value === value)
                      ?.label || value;
                  return <div>Preview: {preview}</div>;
                }}
                onChange={(v) => {
                  this.setState({ preview: v });
                }}
              />
            </Col>
          </Row>
        </Grid>
        <Divider>Select scale</Divider>
        {this.state.type !== undefined && this.state.tonic !== undefined ? (
          <div>
            <Scale scale={this.state.type} note={this.state.tonic} />
            <ChartScale
              scale_short={this.state.type}
              scale_note_tonic={this.state.tonic}
              fret={this.state.fret}
              preview={this.state.preview}
              sw_all_note={true}
            />
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { theme: state.themeReducer.theme };
};

export default connect(mapStateToProps)(SelectScale);

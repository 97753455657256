import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { HashRouter as Router /*, Link*/ } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styles from './HeaderNavbar.module.less';
import { Dropdown, Navbar, Nav, Icon } from 'rsuite';
import { ReactService } from '../../ReactService';

interface Props {
  theme: string; // redux
}

class HeaderNavbar extends React.Component<Props, unknown> {
  public componentWillMount(): void {
    ReactService.importThemeLess(this.props.theme);
  }

  public render(): JSX.Element {
    return (
      <Router>
        <Navbar appearance="inverse" className={styles['App-navbar']}>
          <Navbar.Body>
            <Nav>
              {/*<a href={'https://www.stephane-bressani.ch'}>
                <Nav.Item
                  className={styles['App-navbar-content']}
                  icon={<Icon icon="home" />}
                >
                  Home (www.stephane-bressani.ch)
                </Nav.Item>
                </a>*/}
              <HashLink
                to={'/#_main'}
                scroll={(el: HTMLElement) =>
                  el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
              >
                <Nav.Item className={styles['App-navbar-content']}>
                  Ukulele Helper for iOs
                </Nav.Item>
              </HashLink>
              <HashLink
                to={'/privacy#_main'}
                scroll={(el: HTMLElement) =>
                  el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
              >
                <Nav.Item className={styles['App-navbar-content']}>
                  Privacy Policy
                </Nav.Item>
              </HashLink>
              <a href="mailto:stephane@bressani.dev">
                <Nav.Item className={styles['App-navbar-content']}>
                  Contact me
                </Nav.Item>
              </a>
              {/*
              <HashLink
                to={'/chord#main'}
                scroll={(el: HTMLElement) =>
                  el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
              >
                <Nav.Item className={styles['App-navbar-content']}>
                  Chords
                </Nav.Item>
              </HashLink>
              <HashLink
                to={'/scale#main'}
                scroll={(el: HTMLElement) =>
                  el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
              >
                <Nav.Item className={styles['App-navbar-content']}>
                  Scales
                </Nav.Item>
                </HashLink>*/}
            </Nav>
            {
              <Nav pullRight>
                <Dropdown
                  title="Settings"
                  className={styles['App-navbar-content']}
                  icon={<Icon icon="cog" />}
                >
                  <HashLink
                    to={'/theme#main'}
                    scroll={(el: HTMLElement) =>
                      el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }
                  >
                    <Dropdown.Item icon={<Icon icon="gear-circle" />}>
                      Theme
                    </Dropdown.Item>
                  </HashLink>
                </Dropdown>
              </Nav>
            }
          </Navbar.Body>
        </Navbar>
      </Router>
    );
  }
}
const mapStateToProps = (state: RootState) => {
  return { theme: state.themeReducer.theme };
};

export default connect(mapStateToProps)(HeaderNavbar);

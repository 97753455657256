import React from 'react';
import styles from './HeaderMain.module.less';
import HeaderBackground from './HeaderBackground';
// import logo from './assets/my_ukulele.png';
// import title462 from './assets/title-462.png';
// import title600 from './assets/title-600.png';
// import title768 from './assets/title-768.png';
// import title1024 from './assets/title-1024.png';
// import title1200 from './assets/title-1200.png';

export default function HeaderMain(): JSX.Element {
  return (
    <div className={styles['App-header']}>
      <div className={styles['App-header-linear-gradient']}>
        <HeaderBackground />
        {/*
        <picture>
          <source
            srcSet={title1200}
            className={styles['App-header-logo']}
            media="(min-width: 1200px)"
          />
          <source
            srcSet={title1024}
            className={styles['App-header-logo']}
            media="(min-width: 1024px)"
          />
          <source
            srcSet={title768}
            className={styles['App-header-logo']}
            media="(min-width: 768px)"
          />
          <source
            srcSet={title600}
            className={styles['App-header-logo']}
            media="(min-width: 600px)"
          />
          <img
            alt="Title - Ukulele helper"
            src={title462}
            className={styles['App-header-logo']}
          />
        </picture>
        *}
        {/*<img src={logo} className={styles['App-logo']} alt="Ukulele helper" />*/}
      </div>
    </div>
  );
}

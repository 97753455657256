import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { Loader } from 'rsuite';
import { ScaleService } from './ScaleService';

interface Props {
  theme: string; // redux
  scale?: string;
  note?: string;
}

interface States {
  wasm: any;
}
class Scale extends React.Component<Props, States> {
  public state: States = { wasm: null };

  public componentDidMount(): void {
    this.loadWasm();
    if (this.state.wasm) {
      // TODO secure this
      this.setState({
        wasm: this.state.wasm,
      });
    }
  }

  private loadWasm = async () => {
    try {
      const wasm = await import('chartgeneratormodulewasm');
      this.setState({ wasm: wasm });
    } catch (err: any) {
      console.log(`Unexepcted error in loadWasm. [Message: ${err.message}]`); // TODO rust err handling
    }
  };

  private svg = (scale?: string, note?: string): JSX.Element => {
    if (scale === undefined || note === undefined) {
      return <div></div>;
    } else {
      // TODO alt + fret
      try {
        let theme = 'L';
        if (this.props.theme === 'A') {
          if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            theme = 'D';
          } else {
            theme = 'L';
          }
        } else {
          if (this.props.theme === 'D') {
            theme = 'D';
          }
        }
        let service = new ScaleService(this.state.wasm, theme);
        const svg = service.getSvg(scale, note);
        return (
          <div>
            <img src={'data:image/svg+xml;base64, ' + svg} alt={'TODO'} />
          </div>
        );
      } catch (err) {
        return (
          <div>ERROR TODO Toaster + picture error . This is catched: {err}</div>
        );
      }
    }
  };

  public render(): JSX.Element {
    return (
      <div>
        {this.state.wasm ? (
          <div>{this.svg(this.props.scale, this.props.note)}</div>
        ) : (
          <Loader content="Loading... please wait..." />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { theme: state.themeReducer.theme };
};

export default connect(mapStateToProps)(Scale);

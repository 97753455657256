import React from 'react';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Loader } from 'rsuite';
import styles from './Chart.module.less';
import { RootState } from '../../../store/rootReducer';
import { Icon, IconButton } from 'rsuite';

interface Props {
  theme: string; // redux
  data: string;
}

class Preview extends React.Component<Props, unknown> {
  public constructor(props) {
    super(props);
    this.playAudio = this.playAudio.bind(this);
  }
  /* for html5 but not the same randering between safari and other browser
  public componentDidMount(): void {
    ReactDOM.findDOMNode(this)?.addEventListener(
      'play',
      function (e) {
        // get all <audio> tag elements in the page.
        var allAudios = document.getElementsByTagName('audio');
        // Iterate through all players and pause them, except for
        // the one who fired the "play" event ("target")
        for (let i = 0; i < allAudios.length; i++) {
          if (allAudios[i] !== e.target) {
            allAudios[i].pause();
          }
        }
      },
      true
    );
  }

  public componentWillUnmount(): void {
    ReactDOM.findDOMNode(this)?.removeEventListener(
      'play',
      function (e) {
        // get all <audio> tag elements in the page.
        var allAudios = document.getElementsByTagName('audio');
        // Iterate through all players and pause them, except for
        // the one who fired the "play" event ("target")
        for (let i = 0; i < allAudios.length; i++) {
          if (allAudios[i] !== e.target) {
            allAudios[i].pause();
          }
        }
      },
      true
    );
    }*/

  public playAudio(): void {
    const audio = new Audio();
    const src: string = this.props.data;
    audio.src = src;
    audio.load();
    audio.play();
  }

  /*
   * Render in JSX
   */
  public render(): JSX.Element {
    return (
      <div className={styles['inline']}>
        {this.props.data ? (
          <div className={styles['inline']}>
            <IconButton
              icon={<Icon icon={'music'} />}
              onClick={this.playAudio}
              circle
              size={'lg'}
            />
            {/*<audio
              id={'play'}
              className={styles['audio']}
              controls
              preload={'none'}
              src={this.props.data}
          />*/}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { theme: state.themeReducer.theme };
};

export default connect(mapStateToProps)(Preview);

import { UkuleleWasm } from 'chartgeneratormodulewasm/index';

/*
 * Service between wasm/rust and typescript
 */
export class ScaleService {
  private wasm: UkuleleWasm;

  /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
  public constructor(wasm: any, theme: string) {
    this.wasm = new wasm.UkuleleWasm(theme);
  }

  /*
   * Free memory
   */
  public free(): void {
    this.wasm.free();
  }

  /*
   * Get scale svg
   *
   * @param scale short scale name
   * @param note tonic
   * // TODO @param fret Fret position: 0, 5, 7, 9, 12
   *
   * @return string
   */
  public getSvg(scale: string, note: string): string {
    if (scale === undefined || note === undefined) {
      return '';
    } else {
      return this.wasm.scale_svg_unique(scale, note);
    }
  }
}

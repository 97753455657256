import React, { useState, useEffect } from 'react';
import { Animate } from 'react-move';
import { easeQuadOut } from 'd3-ease';
import styles from './HeaderBackground.module.less';

type AnimatedChordProps = {
  curveIntensity: number;
  position: number;
};

const OFFSET_POS = 20;
const DELAY = 50; // milisecond

const valuesAnimation = [
  0,
  1,
  2,
  3,
  4,
  5,
  4,
  3,
  1,
  1,
  0,
  -1,
  -2,
  -3,
  -4,
  -5,
  -4,
  -3,
  -2,
  -1,
];

function useTick(delay: number, initialIndex: number) {
  const [tick, setTick] = useState(initialIndex ? initialIndex : 0);
  if (tick >= valuesAnimation.length) setTick(() => 0);
  useEffect(() => {
    const interval = setInterval(() => {
      //if (!document.hidden) {
      setTick((tick: number) => tick + 1);
      //}
    }, delay);
    return () => clearInterval(interval);
  }, []); // eslint-disable-line
  return tick;
}

function AnimatedChord(props: AnimatedChordProps) {
  const { curveIntensity } = props;
  return (
    <Animate
      start={{ curveIntensity }}
      enter={{
        curveIntensity: [curveIntensity],
        timing: { duration: DELAY },
      }}
      update={{
        curveIntensity: [curveIntensity],
        timing: { duration: DELAY, ease: easeQuadOut },
      }}
    >
      {(state) => (
        <SingleStringSvgPath {...props} curveIntensity={state.curveIntensity} />
      )}
    </Animate>
  );
}

function SingleStringSvgPath(props: AnimatedChordProps): JSX.Element {
  const svgLineStyle = {
    stroke: '#FFFFFF66',
    strokeWidth: '5px',
    fill: 'none',
  };
  return (
    <path
      d={
        'M' +
        Number(props.position * OFFSET_POS) +
        ',0 Q' +
        Number(props.position * OFFSET_POS + props.curveIntensity) +
        ',50 ' +
        Number(props.position * OFFSET_POS) +
        ',100'
      }
      style={svgLineStyle}
      strokeLinecap="butt"
      height="100%"
      width="100%"
    />
  );
}

export default function HeaderBackground(): JSX.Element {
  const index = useTick(DELAY, 0);
  const svgStyle = {
    width: '100%',
    height: '100%',
    zIndex: 2,
  };

  const stringsChords: JSX.Element[] = [];

  for (let i = 0; i < 4; i++) {
    stringsChords.push(
      <AnimatedChord
        key={i}
        curveIntensity={valuesAnimation[index]}
        position={i + 1}
      />
    );
  }

  return (
    <div className={styles['App-header-svg']}>
      <svg style={svgStyle} viewBox="0 0 100 100" preserveAspectRatio="none">
        {stringsChords}
      </svg>
    </div>
  );
}

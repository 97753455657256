import React from 'react';
import { connect } from 'react-redux';
import { Loader } from 'rsuite';
import styles from './Chart.module.less';
import { WasmService } from '../../../WasmService';
import { ChartService, DtoNote, DtoChord, DtoData } from './ChartService';
import { ReactService } from '../../../ReactService';
import { RootState } from '../../../store/rootReducer';
import Preview from './Preview';
const SAMPLE = require('../assets/ukulele-a-440.wav');

interface Props {
  theme: string; // redux
  scale_short?: string;
  scale_note_tonic?: string;
  sw_all_note: boolean;
  note?: string;
  fret?: number;
  preview?: string;
}

interface States {
  wasm: any;
}

class ChartScale extends React.Component<Props, States> {
  public state: States = { wasm: null };

  private sample: { bytes: Uint8Array } = { bytes: new Uint8Array() };

  public componentDidMount(): void {
    fetch(SAMPLE)
      .then(function (res) {
        console.log(res);
        return res.arrayBuffer();
      })
      .then((ab) => {
        this.sample = { bytes: new Uint8Array(ab) }; //TODO better
        const wasm = new WasmService();
        wasm
          .loadWasm()
          .then((success) => this.setState({ wasm: success }))
          .catch((err) => console.log(err)); // TODO better
      })
      .catch((e) => {
        console.warn('failled to load sample', e); //TODO better
      });
  }

  /*
   * Render the Dto in JSX
   */
  private renderDto = (): JSX.Element => {
    if (
      this.props.scale_short === undefined ||
      this.props.scale_note_tonic === undefined ||
      (!this.props.sw_all_note && this.props.note === undefined) ||
      this.props.fret === undefined
    ) {
      return <div />;
    }
    const service = new ChartService(
      this.state.wasm,
      this.sample.bytes,
      ReactService.computeTheme(this.props.theme)
    );
    try {
      const dto = service.getChartScale(
        this.props.sw_all_note,
        this.props.scale_short!,
        this.props.scale_note_tonic!,
        this.props.fret,
        this.props.note!
      );
      let preview: string[] = [];
      // TODO in ReactService
      if (this.props.preview === undefined) {
        // continue
      } else if (this.props.preview === 'no') {
        // continue
      } else {
        if (this.props.preview === 'all') {
          preview = ['chord', 'arp8', 'arp4'];
        } else {
          preview.push(this.props.preview.toString());
        }
      }
      const jsx = (
        <div>
          {dto.map((res_note: DtoNote, i: number) => (
            <div key={i + '_' + res_note.note}>
              {res_note.chord.map((res_chord: DtoChord, j: number) => (
                <div
                  key={i + '_' + j + '_' + res_chord.chord}
                  className={styles['inline']}
                >
                  {res_chord.data.map((res_data: DtoData, k: number) => (
                    <div
                      key={i + '_' + j + '_' + k + '_'}
                      className={styles['inline']}
                    >
                      <img
                        key={i + '_' + j + '_' + k + '_' + res_chord.chord}
                        src={'data:image/svg+xml;base64, ' + res_data.svg}
                        alt={res_chord.chord}
                        className={styles['chart']}
                      />
                      {preview.map((variation: string, l: number) => (
                        <div key={l + '_preview'} className={styles['inline']}>
                          <Preview
                            data={service.getWav(variation, res_data.semitones)}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      );
      //service.free(); // TODO after test if compile
      return jsx;
    } catch (err) {
      console.log(err);
      return <div>ERROR</div>;
    } // TODO better
  };

  /*
   * Render in JSX
   */
  public render(): JSX.Element {
    return (
      <div>
        {this.state.wasm ? (
          <div>
            <div>{this.renderDto()}</div>
          </div>
        ) : (
          <Loader content="Loading... please wait..." />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { theme: state.themeReducer.theme };
};

export default connect(mapStateToProps)(ChartScale);

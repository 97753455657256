import { UkuleleWasm } from 'chartgeneratormodulewasm/index';

export interface DtoNote {
  note: string;
  chord: DtoChord[];
}

export interface DtoChord {
  chord: string;
  data: DtoData[];
}

export interface DtoData {
  svg: string;
  semitones: number[];
}

/*
 * Service between wasm/rust and typescript
 */
export class ChartService {
  private wasm: UkuleleWasm;
  private bytes: Uint8Array;

  /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
  public constructor(wasm: any, bytes: Uint8Array, theme: string) {
    this.wasm = new wasm.UkuleleWasm(theme);
    this.bytes = bytes;
  }

  /*
   * Free memory
   */
  public free(): void {
    this.wasm.free();
  }

  /*
   * Give all chord possibility
   *
   * @param note note filter or empty
   * @param chord chord filter or 'all'/empty
   * @param fret Fret position: 0, 5, 7, 9, 12
   *
   * @return DtoNote[]
   */
  public getChart(note?: string, chord?: string, fret?: number): DtoNote[] {
    if (note === undefined) {
      note = '';
    }
    if (chord === undefined) {
      chord = 'all';
    }
    if (fret === undefined) {
      return [];
    } else {
      return JSON.parse(this.wasm.chord_list(note, chord, fret));
    }
  }

  /*
   * Generate base64 wave from wasm
   *
   * @param semitones midi hexa note in number[]
   * @param variation: "chord", "arp8", "arp4"
   *
   * @return string
   */
  public getWav(variation: string, semitones: number[]): string {
    try {
      let ab = this.wasm.generate_wav(
        variation,
        new Uint8Array(semitones),
        this.bytes
      );
      return 'data:audio/wav;base64,' + Buffer.from(ab).toString('base64');
    } catch (err) {
      alert(err);
      return ''; // TODO better
    }
  }

  /*
   * Give all chord possibility for a scale rooted on a note tonic and
   * pre-filtred by a fret position (without that fret filter the process is
   * slow)
   *
   * @param sw_all_note Switch boolean if filter on note (with param note) or
   * without
   * @param scale_short Short scale name: "maj_pentagonic", "phrygian",
   * "phrygian_dominant"
   * @param scale_note_tonic Tonic note of the scale
   * @param fret Fret position: 0, 5, 7, 9, 12
   * @param note Optional note filter (depend on parameter sw_all_note)
   *
   * @return DtoNote[]
   */
  public getChartScale = (
    sw_all_note: boolean,
    scale_short?: string,
    scale_note_tonic?: string,
    fret?: number,
    note?: string
  ): DtoNote[] => {
    if (
      scale_short === undefined ||
      scale_note_tonic === undefined ||
      (!sw_all_note && note === undefined) ||
      fret === undefined
    ) {
      return [];
    } else {
      const res = JSON.parse(
        this.wasm.scale_chord_list(scale_short, scale_note_tonic, fret, '') // TODO better than degre = ''
      );
      //this.wasm.free(); // TODO null pointer error ?
      return res;
    }
  };
}

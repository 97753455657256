import React, { RefObject } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  Divider,
  SelectPicker,
  SelectPickerProps,
  Icon,
} from 'rsuite';
import Chart from '../Chart/Chart';
import {
  SelectService,
  DtoSelectNote,
  DtoSelectChord,
  DtoSelectFret,
  DtoSelectPreview,
} from './SelectService';
import { RootState } from '../../../store/rootReducer';
import { ReactService } from '../../../ReactService';
import { WasmService } from '../../../WasmService';

interface Props {
  theme: string; // redux
}

interface States {
  wasm: any;
  note: string;
  chord: string;
  fret: number;
  preview: string;
}

class SelectChord extends React.Component<Props, States> {
  private noteRef: RefObject<SelectPickerProps> = React.createRef();
  private chordRef: RefObject<SelectPickerProps> = React.createRef();
  private fretRef: RefObject<SelectPickerProps> = React.createRef();
  private previewRef: RefObject<SelectPickerProps> = React.createRef();

  public state: States = {
    wasm: null,
    note: 'C',
    chord: '',
    fret: 0,
    preview: 'all',
  };

  public componentDidMount(): void {
    ReactService.importThemeLess(this.props.theme);
    const wasm = new WasmService();
    wasm
      .loadWasm()
      .then((success) => this.setState({ wasm: success }))
      .catch((err) => console.log(err)); // TODO better
  }

  /*
   * Fill Scale and retun DtoSelectNote[]
   */
  private fillSelectNote(): DtoSelectNote[] {
    const sw_all = true;
    const dto = SelectService.getNote(sw_all);
    return dto;
  }

  /*
   * Fill Scale and retun DtoSelectNote[]
   */
  private fillSelectChord(): DtoSelectChord[] {
    const sw_all = true;
    const dto = SelectService.getChord(sw_all);
    return dto;
  }

  /*
   * Fill Scale and retun DtoSelectFret[]
   */
  private fillSelectFret(): DtoSelectFret[] {
    const dto = SelectService.getFret();
    return dto;
  }

  /*
   * Fill fret and return DtoSelectFret[]
   */
  private fillSelectPreview(): DtoSelectPreview[] {
    const dto = SelectService.getPreview(true);
    return dto;
  }

  public render(): JSX.Element {
    return (
      <div>
        <h1>Select chord</h1>
        <Grid fluid>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={6}>
              <SelectPicker
                ref={this.noteRef}
                data={this.fillSelectNote()}
                value={this.state.note}
                cleanable={false}
                block
                placeholder="Select note"
                renderMenuItem={(label) => {
                  const icon: string =
                    this.fillSelectNote().find((x) => x.label === label)
                      ?.icon || 'circle-o';
                  // white note
                  if (icon === 'music')
                    return (
                      <div>
                        <Icon icon={'music'} /> {label}
                      </div>
                    );
                  else if (icon === 'circle-o')
                    return (
                      <div>
                        <Icon icon={'circle-o'} /> {label}
                      </div>
                    );
                  // black note
                  else
                    return (
                      <div>
                        <Icon icon={'circle'} /> {label}
                      </div>
                    );
                }}
                renderValue={(value) => {
                  const item =
                    this.fillSelectNote().find((x) => x.value === value)
                      ?.label || value;
                  return <div>Note: {item}</div>;
                }}
                onChange={(v) => {
                  if (v === '') {
                    this.setState({ note: v, preview: 'no' });
                  } else {
                    this.setState({ note: v });
                  }
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <SelectPicker
                ref={this.chordRef}
                data={this.fillSelectChord()}
                value={this.state.chord}
                cleanable={false}
                block
                placeholder="Select chord type"
                renderMenuItem={(label) => {
                  return (
                    <div>
                      <Icon icon={'music'} /> {label}
                    </div>
                  );
                }}
                renderValue={(value) => {
                  const chord: string =
                    this.fillSelectChord().find((x) => x.value === value)
                      ?.label || value;
                  return <div>Type: {chord}</div>;
                }}
                onChange={(v) => {
                  if (v === 'all') {
                    this.setState({ chord: v, preview: 'no' });
                  } else {
                    this.setState({ chord: v });
                  }
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <SelectPicker
                ref={this.fretRef}
                data={this.fillSelectFret()}
                value={this.state.fret}
                cleanable={false}
                block
                placeholder="Select fret position"
                renderMenuItem={(label) => {
                  return <div>{label}</div>;
                }}
                renderValue={(value) => {
                  const fret: string =
                    this.fillSelectFret().find((x) => x.value === value)
                      ?.label || value;
                  return <div>Type: {fret}</div>;
                }}
                onChange={(v) => {
                  this.setState({ fret: v });
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <SelectPicker
                ref={this.previewRef}
                data={this.fillSelectPreview()}
                value={this.state.preview}
                cleanable={false}
                block
                disabled={this.state.note === '' || this.state.chord === 'all'}
                placeholder="Select music preview"
                renderMenuItem={(label) => {
                  const icon: string =
                    this.fillSelectPreview().find((x) => x.label === label)
                      ?.icon || 'ban';
                  // music preview
                  if (icon === 'music')
                    return (
                      <div>
                        <Icon icon={'music'} /> {label}
                      </div>
                    );
                  // no preview
                  else
                    return (
                      <div>
                        <Icon icon={'ban'} /> {label}
                      </div>
                    );
                }}
                renderValue={(value) => {
                  const preview: string =
                    this.fillSelectPreview().find((x) => x.value === value)
                      ?.label || value;
                  return <div>Preview: {preview}</div>;
                }}
                onChange={(v) => {
                  this.setState({ preview: v });
                }}
              />
            </Col>
          </Row>
        </Grid>
        <Divider>Select chord</Divider>
        <Chart
          note={this.state.note}
          chord={this.state.chord}
          fret={Number(this.state.fret)}
          preview={this.state.preview}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { theme: state.themeReducer.theme };
};

export default connect(mapStateToProps)(SelectChord);

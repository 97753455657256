/*
 * Service for this React project
 */
export class ReactService {
  /*
   * Compute theme into 'L' Light / 'D' Dark
   * Without 'A' Automatic
   */
  static computeTheme(theme: string): string {
    let t = 'L';
    if (theme === 'A') {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        t = 'D';
      } else {
        t = 'L';
      }
    } else {
      if (theme === 'D') {
        t = 'D';
      }
    }
    return t;
  }

  /*
   * Import theme
   */
  static importThemeLess(theme: string): void {
    if (theme === 'A') {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        require('rsuite/lib/styles/themes/dark/index.less');
      } else {
        require('rsuite/lib/styles/themes/default/index.less');
      }
    } else {
      if (theme === 'D') {
        require('rsuite/lib/styles/themes/dark/index.less');
      } else {
        require('rsuite/lib/styles/themes/default/index.less');
      }
    }
  }
}

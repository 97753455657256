import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import * as actionTypes from '../../store/action';
import { RootState } from '../../store/rootReducer';
import { HashLink } from 'react-router-hash-link';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import SelectChord from './Select/SelectChord';
import SelectScale from './Select/SelectScale';
import Settings from './Settings';
import styles from './Main.module.less';
import iosPreview from './assets/ios.png';
import ios1P1 from './assets/web6.5-1.png';
import ios1P2 from './assets/web6.5-2.png';
import ios1P3 from './assets/web6.5-3.png';
import ios1P5 from './assets/web6.5-5.png';
import ios1P6 from './assets/web6.5-6.png';
import ios1P7 from './assets/web6.5-7.png';
import ios2P1 from './assets/webipad3-1.png';
import ios2P2 from './assets/webipad3-2.png';
import ios2P3 from './assets/webipad3-3.png';
import ios2P5 from './assets/webipad3-5.png';
import ios2P6 from './assets/webipad3-6.png';
import ios2P7 from './assets/webipad3-7.png';

interface Props {
  cookies: Cookies; // cookie
  theme: string; // redux
  changeTheme: (value: string) => void; // redux
}

class Main extends React.Component<Props, unknown> {
  public componentWillMount(): void {
    // 1 check if value in redux state
    if (
      this.props.theme === 'A' ||
      this.props.theme === 'L' ||
      this.props.theme === 'D'
    ) {
      // Ok
    } else {
      // No value
      // 2 check if value in cookies
      const { cookies } = this.props;
      const themeCookies = cookies.get('theme');
      if (
        themeCookies === 'A' ||
        themeCookies === 'L' ||
        themeCookies === 'D'
      ) {
        // Ok
        // Set redux
        this.props.changeTheme(this.props.theme);
      } else {
        // Default value
        // Set redux
        this.props.changeTheme('A');
      }
    }
  }

  public render(): JSX.Element {
    let styles_main_content = 'Main-content';
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      if (this.props.theme === 'A') {
        styles_main_content = 'Main-content-dark';
      }
    }
    if (this.props.theme === 'D') {
      styles_main_content = 'Main-content-dark';
    }
    return (
      <Router>
        <div className={styles['Main-background']}>
          <div className={styles['Main-background-zone']}>
            <div className={styles['Main-background-linear-gradient']}>
              <div className={styles[styles_main_content]}>
                <Switch>
                  <Route exact path="/scale" render={() => <SelectScale />} />
                  <Route exact path="/chord" render={() => <SelectChord />} />
                  <Route
                    exact
                    path="/theme"
                    render={() => <Settings cookies={this.props.cookies} />}
                  />
                  <Route exact path="/privacy">
                    <div>
                      <a id={'_main'} href="#main">
                        <abbr title={'Privacy Policy'}></abbr>
                      </a>
                      <h1>Privacy Policy</h1>
                      <div className={styles['Main-content-left']}>
                        The application "Ukulele Helper” as been developed by
                        Stéphane Bressani as a commercial service.
                        <br />
                        <br />
                        This page is used to inform visitors that we do not
                        collect, use, save, or have access to any of your
                        personal data recorded in these apps.
                        <br />
                        <br />
                        We may update our Privacy Policy from time to time.
                        Thus, you are advised to review this page periodically
                        for any changes. We will notify you of any changes by
                        posting the new Privacy Policy on this page.
                        <br />
                        <br />
                        This policy is effective as of December 8th, 2020.
                        <br />
                        <br />
                        If you have any questions about the Privacy Policy, do
                        not hesitate to contact me at{' '}
                        <a href="mailto:stephane@bressani.dev">
                          stephane@bressani.dev
                        </a>
                      </div>
                      <div>
                        <br />
                        Copyright 2021 Stéphane Bressani
                      </div>
                    </div>
                  </Route>
                  <Route path="/">
                    <div>
                      <a id={'_main'} href="#main">
                        <abbr title={'Ukulele helper for iOs'}></abbr>
                      </a>
                      <h1>Ukulele helper for iOs</h1>
                      <div>
                        <a href="https://apps.apple.com/us/app/ukulele-helper/id1543704350">
                          <img src={iosPreview} alt={'App icon'} />
                        </a>
                      </div>
                      <div className={styles['Main-content-left']}>
                        <br />
                        You can find Ukulele Helper on the App Store{' '}
                        <a href="https://apps.apple.com/us/app/ukulele-helper/id1543704350">
                          here
                        </a>
                        <br />
                        <br />
                        You can do the following with this app:
                        <br />
                        - Display chords
                        <br />
                        - Preview audio with click on the pictures chords, with:
                        chord (all notes played together) and 2 types of
                        arpeggiators
                        <br />
                        - Display scales in portrait (Major, Minor, Major
                        Pentagonic and Minor Pentatonic) more scale avaiable
                        In-App purchase
                        <br />
                        - Chord linked with scale ordered by degree
                        <br />
                        - Tuning for whole application on C, D or G with preview
                        audio of separate strings
                        <br />
                        - Support light and dark mode
                        <br />
                        <br />
                        You can{' '}
                        <a href="mailto:stephane@stephanebressani.dev">
                          contact me (Stéphane Bressani)
                        </a>{' '}
                        for any questions/suggestions. You can read{' '}
                        <HashLink
                          to={'/privacy#_main'}
                          scroll={(el: HTMLElement) =>
                            el.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start',
                            })
                          }
                        >
                          here
                        </HashLink>{' '}
                        the privacy policy of this app.
                        <br />
                      </div>
                      <div>
                        <br />
                        <img src={ios1P1} alt={'iPhone screnshot'} />
                        <img src={ios1P2} alt={'iPhone screnshot'} />
                        <img src={ios1P3} alt={'iPhone screnshot'} />
                        <img src={ios1P5} alt={'iPhone screnshot'} />
                        <img src={ios1P6} alt={'iPhone screnshot'} />
                        <img src={ios1P7} alt={'iPhone screnshot'} />
                      </div>

                      <div className={styles['Main-content-left']}>
                        <br />
                      </div>
                      <div>
                        <img src={ios2P1} alt={'iPad screnshot'} />
                        <img src={ios2P2} alt={'iPad screnshot'} />
                        <img src={ios2P3} alt={'iPad screnshot'} />
                        <img src={ios2P5} alt={'iPad screnshot'} />
                        <img src={ios2P6} alt={'iPad screnshot'} />
                        <img src={ios2P7} alt={'iPad screnshot'} />
                        <br />
                      </div>
                      <div>
                        <br />
                        Copyright 2020 Stéphane Bressani
                      </div>
                    </div>
                  </Route>
                </Switch>
                <a id={'main'} href="#main">
                  <abbr title={'Content of the page'}></abbr>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return { theme: state.themeReducer.theme, cookies: ownProps.cookies };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  changeTheme: (value: string) => dispatch(changeTheme(value)),
});

const changeTheme = (value: string) => ({
  type: actionTypes.CHANGE_THEME,
  value,
});

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(Main));

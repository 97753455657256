import { UkuleleWasm } from 'chartgeneratormodulewasm/index';

export interface DtoSelectNote {
  label: string;
  value: string;
  icon: string;
}

export interface DtoSelectChord {
  label: string;
  value: string;
}

export interface DtoSelectScale {
  label: string; // long
  value: string; // short
}

interface DtoSelectScaleOriginal {
  short: string;
  long: string;
}

export interface DtoSelectFret {
  label: string;
  value: number;
}

export interface DtoSelectPreview {
  label: string;
  value: string;
  icon: string;
}

/*
 * Service between wasm/rust and typescript
 */
export class SelectService {
  private wasm: UkuleleWasm;

  /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
  public constructor(wasm: any, theme: string) {
    this.wasm = new wasm.UkuleleWasm(theme);
  }

  /*
   * Free memory
   */
  public free(): void {
    this.wasm.free();
  }

  /*
   * Return static note array dto
   */
  static getNote(sw_all?: boolean): DtoSelectNote[] {
    const dto = [
      {
        label: 'C',
        value: 'C',
        icon: 'circle-o',
      },
      {
        label: 'C#/Db',
        value: 'C#',
        icon: 'circle',
      },
      {
        label: 'D',
        value: 'D',
        icon: 'circle-o',
      },
      {
        label: 'D#/Eb',
        value: 'D#',
        icon: 'circle',
      },
      {
        label: 'E',
        value: 'E',
        icon: 'circle-o',
      },
      {
        label: 'F',
        value: 'F',
        icon: 'circle-o',
      },
      {
        label: 'F#/Gb',
        value: 'F#',
        icon: 'circle',
      },
      {
        label: 'G',
        value: 'G',
        icon: 'circle-o',
      },
      {
        label: 'G#/Ab',
        value: 'G#',
        icon: 'circle',
      },
      {
        label: 'A',
        value: 'A',
        icon: 'circle-o',
      },
      {
        label: 'A#/Bb',
        value: 'A#',
        icon: 'circle',
      },
      {
        label: 'B',
        value: 'B',
        icon: 'circle-o',
      },
    ];
    if (sw_all === undefined) {
      sw_all = false;
    }
    if (sw_all) {
      dto.push({
        label: 'All',
        value: '',
        icon: 'music',
      });
    }
    return dto;
  }

  /*
   * Return static chord array dto
   */
  static getChord(sw_all?: boolean): DtoSelectChord[] {
    const dto = [
      {
        label: 'Major',
        value: '',
      },
      {
        label: 'Minor',
        value: 'm',
      },
      {
        label: 'Suspended second',
        value: 'sus2',
      },
      {
        label: 'Suspended fourth',
        value: 'sus4',
      },
      {
        label: 'Augmented',
        value: 'aug',
      },
      {
        label: 'Diminished',
        value: 'dim',
      },
      {
        label: 'Dominant 7th',
        value: '7',
      },
      {
        label: 'Minor 7th',
        value: 'm7',
      },
      {
        label: 'Major 7th',
        value: 'maj7',
      },
      {
        label: 'Augmented 7th',
        value: 'aug7',
      },
      {
        label: 'Augmented major 7th',
        value: 'augMaj7',
      },
      {
        label: 'Diminished 7th',
        value: 'dim7',
      },
      {
        label: 'Half-diminished 7th',
        value: 'm7b5',
      },
    ];
    if (sw_all === undefined) {
      sw_all = false;
    }
    if (sw_all) {
      dto.push({
        label: 'All',
        value: 'all',
      });
    }
    return dto;
  }

  /*
   * Return all scale possibility
   *
   * @return DtoSelectScale[]
   */
  public getScale(): DtoSelectScale[] {
    return JSON.parse(this.wasm.scale_list_select()).map(
      (res: DtoSelectScaleOriginal): DtoSelectScale => {
        return { label: res.long, value: res.short } as DtoSelectScale;
      }
    );
  }

  /*
   * Return static chord array dto
   */
  static getFret(): DtoSelectFret[] {
    const dto = [
      {
        label: 'Small',
        value: 0,
      },
      {
        label: 'Medium',
        value: 5,
      },
      {
        label: 'Long',
        value: 7,
      },
      {
        label: 'Extra long',
        value: 10,
      },
      {
        label: 'Extra extra long',
        value: 12,
      },
    ];
    return dto;
  }

  /*
   * Return static preview array dto
   */
  static getPreview(sw_all?: boolean): DtoSelectPreview[] {
    const dto = [
      {
        label: 'No music preview',
        value: 'no',
        icon: 'ban',
      },
      {
        label: 'Type 1 - Chord',
        value: 'chord',
        icon: 'music',
      },
      {
        label: 'Type 2 - 8 note patern',
        value: 'arp8',
        icon: 'music',
      },
      {
        label: 'Type 3 - 4 note patern',
        value: 'arp4',
        icon: 'music',
      },
    ];
    if (sw_all === undefined) {
      sw_all = false;
    }
    if (sw_all) {
      dto.push({
        label: 'Type 1 + 2 + 3',
        value: 'all',
        icon: 'music',
      });
    }
    return dto;
  }
}

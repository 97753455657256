import React from 'react';
import styles from './App.module.less';
import 'rsuite/lib/styles/index.less';
import Main from './Component/Main/Main';
import { Container, Header, Content } from 'rsuite';
import HeaderNavbar from './Component/Header/HeaderNavbar';
import HeaderMain from './Component/Header/HeaderMain';
import { RootState } from './store/rootReducer';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';

interface Props {
  cookies: Cookies; // Cookies
  theme: string; // redux
}

class App extends React.Component<Props, unknown> {
  public render(): JSX.Element {
    return (
      <Container className={styles['App']}>
        <Header>
          <HeaderNavbar />
          <HeaderMain />
        </Header>
        <Content>
          <Main cookies={this.props.cookies} />
        </Content>
      </Container>
    );
  }
}

// not remove comment /* */
const mapStateToProps = (state: RootState /*, ownProps: any*/) => {
  return { theme: state.themeReducer.theme /*, cookies: ownProps.cookies*/ };
};

export default connect(mapStateToProps)(withCookies(App));

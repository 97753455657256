import React from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/action';
import { Radio, RadioGroup } from 'rsuite';
import styles from './Main.module.less';
import { AnyAction, Dispatch } from 'redux';
import { RootState } from '../../store/rootReducer';
import { withCookies, Cookies } from 'react-cookie';

interface Props {
  cookies: Cookies;
  theme: string;
  changeTheme: (value: string) => void;
}

interface States {
  theme: string; // TODO themeReducer.theme
}

class Settings extends React.Component<Props, States> {
  public state: States = { theme: this.props.theme };

  private onChangeTheme = (value: string) => {
    const { cookies } = this.props;
    cookies.set('theme', value, { path: '/' });
    this.props.changeTheme(value);
  };

  public render(): JSX.Element {
    return (
      <div>
        <h1>Chose a theme</h1>
        <br />
        <RadioGroup
          name="radioList"
          inline
          appearance="picker"
          value={this.props.theme}
          onChange={this.onChangeTheme}
        >
          <span className={styles['radio-group-label']}>Theme: </span>
          <Radio value="A">Automatic (same as os)</Radio>
          <Radio value="L">Light</Radio>
          <Radio value="D">Dark</Radio>
        </RadioGroup>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return { theme: state.themeReducer.theme, cookies: ownProps.cookies };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  changeTheme: (value: string) => dispatch(changeTheme(value)),
});

const changeTheme = (value: string) => ({
  type: actionTypes.CHANGE_THEME,
  value,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(Settings));

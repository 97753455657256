/*
 * Service for loading wasm
 */
export class WasmService {
  public loadWasm = (): Promise<any> => {
    const promise = new Promise((resolve, reject) => {
      try {
        const wasm = import('chartgeneratormodulewasm');
        resolve(wasm);
      } catch (err: any) {
        reject(`Unexepcted error in loadWasm. [Message: ${err.message}]`); // TODO rust err handling
      }
    });
    return promise;
  };
}
